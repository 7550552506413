// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";
import { User } from "../../core/_models";

type Props = {
  row: Row<User>;
};

const CustomRow: FC<Props> = ({ row }) => {
  console.log(row);
  return (
    <tr {...row.getRowProps()} className={`${row?.original?.needsReview ? "bg-warning" : ""}`}>
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className={clsx({ "text-end min-w-100px": cell.column.id === "actions" })}>
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export { CustomRow };
