import { TimeClockStatus, useTimeclock } from "./hooks/useTimeclock";
import { useTranslate } from "../../../../../../app/function/translate";
import useTimer from "./hooks/useTimer";

const ToolbarTimeClock = () => {
  const { createTimelog, createLunchLog, currentStatus, stateLabel, stateLunchLabel, data } = useTimeclock();
  const translate = useTranslate();

  console.log(currentStatus);

  const { mainTime, lunchTime } = useTimer({
    currentStatus,
    clockIn: currentStatus === TimeClockStatus.CLOCKED_IN,
    clockOut: currentStatus === TimeClockStatus.NO_LOGGED,
    lunchStart: currentStatus === TimeClockStatus.IN_LUNCH,
    clockInDb: data ? data[0]?.clockIn : "",
    info: data ? data[0] : "",
  });

  return (
    <div className='d-flex flex-column flex-sm-row align-items-center gap-2 gap-lg-4'>
      <p
        className='d-flex align-items-center mb-0'
        style={{ fontSize: "16px", lineHeight: "25.41px", marginBottom: "0" }}
      >
        {currentStatus === TimeClockStatus.CLOCKED_IN && mainTime}
        {currentStatus === TimeClockStatus.IN_LUNCH && lunchTime}
      </p>
      <div className='d-flex align-items-center'>
        <p className='me-3 mb-0' style={{ fontSize: "16px", lineHeight: "25.41px", marginBottom: "0" }}>
          {translate("DASHBOARD-USER-LABEL-CE")}
          <b>
            {" "}
            {currentStatus === TimeClockStatus.NO_LOGGED
              ? translate(TimeClockStatus.CLOCKED_OUT)
              : translate(currentStatus)}
          </b>
        </p>
      </div>

      <div className='d-flex align-items-center gap-2'>
        <button
          onClick={createTimelog}
          className={`btn btn-sm btn-success ${
            currentStatus === TimeClockStatus.CLOCKED_IN || currentStatus === TimeClockStatus.IN_LUNCH
              ? "btn-danger"
              : "btn-success"
          }`}
          style={{ fontSize: "16px", lineHeight: "32.41px", marginTop: "8px" }}
        >
          {stateLabel}
        </button>
        <button
          onClick={createLunchLog}
          disabled={currentStatus === TimeClockStatus.CLOCKED_OUT || currentStatus === TimeClockStatus.NO_LOGGED}
          className={`btn btn-sm btn-light-success ${
            currentStatus === TimeClockStatus.IN_LUNCH ? "btn-warning" : "btn-light-success"
          }`}
          style={{ fontSize: "16px", lineHeight: "32.41px", marginTop: "8px" }}
        >
          {stateLunchLabel}
        </button>
      </div>
    </div>
  );
};

export { ToolbarTimeClock };
