import axios from "axios";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";

export const exportToExcel = async (exportData, fileName = "exportedData", templateFilePath = "", startCell = "A2") => {
  if (!Array.isArray(exportData)) {
    console.error("Data is not an array");
    return;
  }

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("Data");

  if (templateFilePath) {
    const _templateFilePath = `${window.location.origin}/templates/${templateFilePath}`;
    const response = await axios.get(_templateFilePath, { responseType: "arraybuffer" });
    const buffer = new Uint8Array(response.data);

    await workbook.xlsx.load(buffer);

    worksheet = workbook.getWorksheet(1) || worksheet;
  } else {
    if (exportData.length > 0) {
      const headers = Object.keys(exportData[0]);
      worksheet.addRow(headers);
    }
  }

  const { rowNum: startRow, colNum: startCol } = cellRefToRowCol(startCell);

  exportData.forEach((row, rowIndex) => {
    Object.entries(row).forEach(([key, value], columnIndex) => {
      const cell = worksheet.getCell(startRow + rowIndex, startCol + columnIndex);
      cell.value = value as ExcelJS.CellValue;
    });
  });

  const date = new Date();
  const dateTimeStamp = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  const exportName = `${fileName}_${dateTimeStamp}${fileExtension}`;

  const buffer = await workbook.xlsx.writeBuffer();

  // Save the buffer to a file
  FileSaver.saveAs(new Blob([buffer], { type: fileType }), exportName);
};

function cellRefToRowCol(cellRef: string) {
  const match = cellRef.match(/^([A-Z]+)(\d+)$/);
  if (match) {
    const colRef = match[1];
    const rowRef = match[2];
    const colNum = colRef.split("").reduce((prev, curr, i, arr) => {
      return prev + (curr.charCodeAt(0) - 64) * Math.pow(26, arr.length - i - 1);
    }, 0);
    const rowNum = parseInt(rowRef, 10);
    return { rowNum, colNum };
  } else {
    throw new Error("Invalid cell reference");
  }
}
